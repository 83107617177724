import React from "react";
import { Banner } from "@shopify/polaris";
const getErrorMessage = (code?: string | boolean) => {
    switch(code) {
        case 'db-permission-denied':
            return 'Permission denied.'
        case 'anonymous-auth-failed':
            return 'Anonymous authentication failed. Try again.'
        case 'page-not-found':
            return 'Sorry the page you are looking for could not be found.'
        default:
            return 'Oops, something went wrong.';
    }
}
const component: React.FC<{errorCode?: string | boolean}> = (props) => {
    const {errorCode} = props;
    const status = 'critical';
    const title = 'There was an error';

    return errorCode ? <Banner title={title} status={status}>{getErrorMessage(errorCode)}</Banner> : null;
}

export default component;