import React, { Component } from "react"
import {
  AppProvider,
  Layout,
  Page,
} from "@shopify/polaris"
import { Provider } from "@shopify/app-bridge-react"
//import styled from 'styled-components'
import axios from "axios"
import {apiKey as getApiKey, appName as getAppName } from "../helpers/app"
const initialState = {
  shop: "",
  missingShop: false,
  hasError: false,
  isLoading: false,
}
import {default as Banner} from '../templates/errors/error-banner'
type State = Readonly<typeof initialState>

class Page404 extends Component {
  readonly state: State = initialState
  componentDidMount() {
    const queryParams = window.location.search
  }

  render() {
    const appName = getAppName();
    const apiKey = getApiKey()
    if (typeof apiKey !== "string") {
      return <p>Missing API key</p>
    }
    const config = {
      apiKey,
      shopOrigin: "myshopify.com",
    }
    return (
      <Provider config={config}>
        <AppProvider forceRedirect={false} i18n={{}}>
          <Page title={`Page not found - ${appName}`}>
            <Layout>
              <Layout.Section>
                <Banner errorCode='page-not-found'/>
              </Layout.Section>
            </Layout>
          </Page>
        </AppProvider>
      </Provider>
    )
  }

  handleChange = (field: string) => {
    return (value: any) => this.setState({ [field]: value })
  }

  onSubmit = () => {
    this.setState({
      missingShop: false,
      isLoading: true,
      hasError: false,
    })

    if (this.state.shop.length > 0) {
      axios
        .post("/auth", {
          shop: this.state.shop,
        })
        .then(response => {
          if (response.data && response.data) {
            const redirectUrl = response.data.body

            if (window.top === window.self) {
              window.top.location.href = redirectUrl
            } else {
              let normalizedLink = document.createElement("a")
              normalizedLink.href = redirectUrl

              const message = JSON.stringify({
                message: "Shopify.API.remoteRedirect",
                data: { location: normalizedLink.href },
              })
              window.parent.postMessage(
                message,
                `https://${this.state.shop}.myshopify.com`
              )
            }
            return
          } else {
            this.setState({
              hasError: true,
              isLoading: false,
            })
            return
          }
        })
        .catch(error => {
          this.setState({
            hasError: error,
            isLoading: false,
          })
        })
    } else {
      this.setState({
        missingShop: true,
        hasError: "Missing shop parameter.",
        isLoading: false,
      })
    }
  }
}
export default Page404